@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
}

body::-webkit-scrollbar {
   
    display: none;
    
  
}

.app_waves-main-layer {
    width: 100%;
    position: fixed;
    bottom: -7vw;
    z-index: 10;
}

.app_waves-secondary-layer {
    width: 100%;
    height: 40vw;
    position: fixed;
    bottom: -10vw;
    z-index: 6;
}

@media screen and (max-width: 1024px) {
    .app_waves-main-layer {
        width: 100%;
        position: fixed;
        bottom: 0vw;
        z-index: 10;
    }
    
    .app_waves-secondary-layer {
        width: 100%;
        height: 40vw;
        position: fixed;
        bottom: -5vw;
        z-index: 6;
    }
}
