.hidden-header {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 4.5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s ease;
}

.hidden-header_hidden {
    transform: translateY(-100%);
}

/* .hidden-header_logo-container {
    margin-left: 1.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5vw;
} */

.hidden-header_logo {
    width: 8vw;
    margin-left: 1.2vw;
}

.hidden-header_button {
    width: 15vw;
    height: 3vw;
    margin-right: 1.2vw;
    background-color: #B480DA;
    border-color: transparent;
    border-radius: 5vw;
    color: white;
    font-size: 1.3vw;
    box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.5);
    cursor: pointer;
    transition: 0.3s ease;
}

@media screen and (max-width: 1024px) {
    .hidden-header {
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 15vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: 0.3s ease;
    }
    
    .hidden-header_hidden {
        transform: translateY(-100%);
    }
    
    .hidden-header_logo {
        width: 22.5vw;
        margin-left: 2vw;
    }
    
    .hidden-header_button {
        width: 40vw;
        height: 8vw;
        margin-right: 2vw;
        margin-top: -1vw;
        background-color: #B480DA;
        border-color: transparent;
        border-radius: 5vw;
        color: white;
        font-size: 3vw;
        box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.5);
        cursor: pointer;
        transition: 0.3s ease;
    }
}