.app-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110vh;
    position: relative;
    z-index: 9;
}

.app-section_main-container {
    width: 70vw;
    height: 30vw;
    border: 0.1vw solid #ADADAD;
    border-radius: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: relative;
}

.app-section_main-container_first-bubble {
    position: absolute;
    width: 8vw;
    height: 8vw;
    border-radius: 50%;
    background-color: #B480DA;
    right: 0;
    top: 0;
    transform: translate(50%,-50%);
}

.app-section_main-container_second-bubble {
    position: absolute;
    width: 12vw;
    height: 12vw;
    border-radius: 50%;
    background-color: #E0C4F6;
    left: 0;
    bottom: 0;
    transform: translate(-50%,50%);
}

.app-section_main-text {
    color: #ADADAD;
    text-align: center;
    width: 100%;
    font-size: 2vw;
}

.app-section_main-app-icons {
    width: 30vw;
}

@media screen and (max-width: 1024px) {
    .app-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        position: relative;
        z-index: 9;
        overflow: hidden;
    }
    
    .app-section_main-container {
        width: 90vw;
        height: 80vw;
        border: 0.1vw solid #ADADAD;
        border-radius: 5vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        position: relative;
    }

    .app-section_main-container_first-bubble {
        position: absolute;
        width: 12.5vw;
        height: 12.5vw;
        border-radius: 50%;
        background-color: #B480DA;
        right: 0;
        top: 0;
        transform: translate(50%,-50%);
    }
    
    .app-section_main-container_second-bubble {
        position: absolute;
        width: 25vw;
        height: 25vw;
        border-radius: 50%;
        background-color: #E0C4F6;
        left: 0;
        bottom: 0;
        transform: translate(-50%,50%);
    }
    
    .app-section_main-text {
        color: #ADADAD;
        text-align: center;
        width: 100%;
        font-size: 6vw;
    }
    
    .app-section_main-app-icons {
        width: 60vw;
    }
}