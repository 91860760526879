.footer {
    width: 100%;
    height: 2.5vw;
    position: fixed;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
}

.footer_text {
    color: white;
    font-size: 1vw;
}

@media screen and (max-width: 1024px) {
    .footer {
        width: 100%;
        height: 8vw;
        position: fixed;
        bottom: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
    }
    
    .footer_text {
        color: white;
        font-size: 3vw;
    }
}

