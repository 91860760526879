.cards {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 9;
}

.cards_card {
    width: 24vw;
    height: 30vw;
    background-color: white;
    box-shadow: 5px 5px 25px 2px rgba(0,0,0,0.5);
    border-radius: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3vw;
}

.cards_card-image {
    width: 7vw;
}

.cards_card-text {
    width: 90%;
    text-align: center;
    color: #ADADAD;
    font-size: 1.2vw;
    margin-top: 2vw;
}

@media screen and (max-width: 1024px) {
    .cards {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        z-index: 9;
    }
    
    .cards_card {
        width: 80vw;
        height: 100vw;
        background-color: white;
        box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.5);
        border-radius: 5vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5vw;
    }
    
    .cards_card-image {
        width: 25vw;
    }
    
    .cards_card-text {
        width: 90%;
        text-align: center;
        color: #ADADAD;
        font-size: 5vw;
        margin-top: 6vw;
    }
}