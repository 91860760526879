.cellphone {
    width: 100vw;
    height: 74vw;
    position: relative;
    margin-top: 10vw;
}

.cellphone_background {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 7;
}

.cellphone_second-phone {
    position: absolute;
    left: -3vw;
    z-index: 9;
    width: 70vw;
}

.cellphone_text-container {
    position: absolute;
    z-index: 8;
    right: 5vw;
    top: 50%;
    transform: translateY(-50%);
    width: 60vw;
    height: 30vw;
    background-color: white;
    border-radius: 2vw;
    box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.cellphone_text-title {
    width: 35vw;
    text-align: center;
    margin-right: 5vw;
    font-size: 2vw;
    color: #8B4DBA;
}
.cellphone_text-list {
    width: 35vw;
    margin-right: 5vw;
    font-size: 1.2vw;
}

.cellphone_text-list-item {
    margin-bottom: 1vw;
    color: black;
}

@media screen and (max-width: 1024px) {
    .cellphone {
        width: 100vw;
        height: 150vh;
        position: relative;
        margin-top: 10vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    
    .cellphone_background {
        width: 100%;
        height: 150vh;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 7;
    }
    
    .cellphone_second-phone {
        position: relative;
        left: 3vw;
        z-index: 8;
        width: 100vw;
        transform: rotate(15deg);
    }
    
    .cellphone_text-container {
        position: relative;
        z-index: 9;
        right: 0vw;
        top: -12vw;
        transform: translateY(0%);
        width: 90vw;
        height: auto;
        background-color: white;
        border-radius: 2vw;
        box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .cellphone_text-title {
        width: 100%;
        margin-top: 3vw;
        text-align: center;
        font-size: 5vw;
        color: #8B4DBA;
    }
    .cellphone_text-list {
        width: 80%;
        font-size: 3.5vw;
        margin-top: -1vw;
    }
    
    .cellphone_text-list-item {
        margin-bottom: 2vw;
        color: black;
    }
}