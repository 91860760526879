.banner {
    width: 100%;
    height: 53vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

.banner_image {
    width: 100%;
    height: 40vw;
    position: absolute;
    z-index: 7;
    left: 0;
    top: 0;
}

.banner_content_container {
    z-index: 9;

}

.banner_content-text-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15vw;
    left: 6vw;
    z-index: 9;
}

.banner_content-button {
    background-color: #B480DA;
    border-color: transparent;
    width: 30vw;
    height: 4.5vw;
    border-radius: 5vw;
    color: white;
    font-size: 1.3vw;
    box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.5);
    cursor: pointer;
    transition: 0.3s ease;
}

.banner_content-button:hover {
    width: 32vw;
}

.banner_content-text {
    width: 80%;
    text-align: center;
    font-size: 2vw;
    color: white;
}

.banner_content-image {
    position: absolute;
    z-index: 8;
    right: 9vw;
    top: 4vw;
    width: 45vw;
    transform: rotate(15deg);
}

@media screen and (max-width: 1024px) {
    .banner {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow-x: hidden;
    }
    
    .banner_image {
        width: 100%;
        height: 80vh;
        position: absolute;
        z-index: 7;
        left: 0;
        top: 0;
    }
    
    .banner_content_container {
        z-index: 9;
    
    }
    
    .banner_content-text-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: initial;
        z-index: 9;
        margin-top: 25vw;
        margin-bottom: 5vw;
    }
    
    .banner_content-button {
        background-color: #B480DA;
        border-color: transparent;
        width: 60vw;
        height: 8vw;
        border-radius: 5vw;
        color: white;
        font-size: 4vw;
        box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.5);
        cursor: pointer;
        transition: 0.3s ease;
    }
    
    .banner_content-button:hover {
        width: 60vw;
    }

    .banner_content-button:active {
        width: 70vw;
    }
    
    .banner_content-text {
        width: 80%;
        text-align: center;
        font-size: 5vw;
        color: white;
    }
    
    .banner_content-image {
        position: initial;
        z-index: 8;
        width: 100%;
        transform: rotate(0deg);
    }
}