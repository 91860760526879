.header {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 4.5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s ease;
    overflow: hidden;
}

.header-hidden {
    transform: translateY(-100%);
}

.header_logo {
    width: 8vw;
    margin-left: 1.2vw;
}

@media screen and (max-width: 1024px) {
    .header {
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 15vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: 0.3s ease;
        overflow: hidden;
    }
    
    .header-hidden {
        transform: translateY(-100%);
    }
    
    .header_logo {
        width: 22.5vw;
        margin-left: 2vw;
    }
}
